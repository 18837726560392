import './ConsumptionBody.less';

import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import Icon from '@ant-design/icons';
import { format } from 'date-fns';
import { Spin } from 'antd';
import ConsumptionTable from '../ConsumptionTable/ConsumptionTable';
import { ConsumptionFiltersOptions } from '../Consumption';
import ConsumptionDetailsBar from './ConsumptionDetailsBar/ConsumptionDetailsBar';
import ConsumptionChart from './ConsumptionChart/ConsumptionChart';
import UnderMaintenance from 'components/SomethingWentWrong/SomethingWentWrong';
import { ReactComponent as ConsumedIcon } from 'images/consumed-icon.svg';
import { ReactComponent as DatabaseIcon } from 'images/database.svg';
import { getClustersConsumption, getDateRangeString } from 'utils/cluster';
import { sum } from 'utils';

export type ChartType = 'line' | 'column' | 'bar';
export type MeasureType = 'daily' | 'hourly' | 'monthly';

interface ConsumptionBodyProps {
  filters: ConsumptionFiltersOptions;
  setFilters: Dispatch<SetStateAction<ConsumptionFiltersOptions>>;
  clustersFormatted?: ReturnType<typeof getClustersConsumption>;
  clusters?: any;
  isLoading: boolean;
  isError: boolean;
  start: Date;
  end: Date;
}

function ConsumptionBody({
  filters,
  setFilters,
  clustersFormatted,
  clusters,
  isLoading,
  isError,
  start,
  end,
}: ConsumptionBodyProps) {
  let [chartType, setChartType] = useState<ChartType>('bar');
  const [measureType, setMeasureType] = useState<MeasureType>('daily');

  let filteredClusters = useMemo(
    () => clustersFormatted?.filter((cluster) => filters.selectedClusters.find((e) => e.value === cluster.id)) ?? [],
    [clustersFormatted, filters.selectedClusters]
  );

  let filteredDataClusters = useMemo(
    () => clusters?.filter((cluster: any) => filters.selectedClusters.find((e) => e.value === cluster.id)) ?? [],
    [clusters, filters.selectedClusters]
  );

  let idsKey = filteredClusters.map((c) => c.id).join('');
  let dateKey = `${format(start, 'yyyy-MM-dd')}${format(end, 'yyyy-MM-dd')}`;
  let filteredClustersKey = `${idsKey}${dateKey}`;

  let dateRangeString = useMemo(() => getDateRangeString({ start, end }), [end, start]);

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spin tip="Getting your consumption info" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="cluster-list flex-center">
        <UnderMaintenance />
      </div>
    );
  }

  if (clustersFormatted?.length === 0 && !filters.selectedClusters?.length) {
    return (
      <div className="cluster-list flex-center">
        <p>No clusters found</p>
      </div>
    );
  }

  let consumedInits: number = 0;
  if (filteredClusters) {
    consumedInits = sum(filteredClusters, (cluster) => sum(cluster.consumption, (con) => con.total));
  }
  return (
    <div className="ConsumptionBody">
      <div className="ConsumptionBody__summary">
        <div className="ConsumptionBody__summary-date-range">{dateRangeString}</div>

        <div className="ConsumptionBody__summary-section-wrapper">
          <div className="ConsumptionBody__summary-section">
            <div className="ConsumptionBody__summary-section-icon">
              <Icon component={ConsumedIcon} />
            </div>
            <div className="ConsumptionBody__summary-section-info">
              <h2>Consumed Incorta Power Units</h2>
              <p>{consumedInits} IPU</p>
            </div>
          </div>

          <div className="ConsumptionBody__summary-section">
            <div className="ConsumptionBody__summary-section-icon">
              <Icon component={DatabaseIcon} />
            </div>
            <div className="ConsumptionBody__summary-section-info">
              <h2>Number of Clusters</h2>
              <p>
                {filters.selectedClusters.length} out of {clustersFormatted?.length} Clusters
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ConsumptionBody__details_container">
        <ConsumptionDetailsBar
          chartType={chartType}
          setChartType={setChartType}
          filteredClusters={filteredClusters}
          clustersFormatted={clustersFormatted}
          isLoading={isLoading}
          setFilters={setFilters}
          filters={filters}
          setMeasureType={setMeasureType}
          measureType={measureType}
        />

        <ConsumptionChart
          measureType={measureType}
          key={filteredClustersKey}
          clusters={filteredDataClusters}
          filteredClusters={filteredClusters}
          chartType={chartType}
        />

        <ConsumptionTable filteredClusters={filteredClusters} />
      </div>
    </div>
  );
}

export default ConsumptionBody;
