import './ConsumptionDetailsBar.less';

import React from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Select, Space, Typography } from 'antd';
import { ChartType, MeasureType } from '../ConsumptionBody';
import ConsumptionFilter from '../ConsumptionFilter/ConsumptionFilter';
import { downloadConsumptionToCSV, getClustersConsumption } from 'utils/cluster';
import { ConsumptionFiltersOptions } from 'components/Consumption/Consumption';
const { Title } = Typography;

interface ConsumptionDetailsBarProps {
  setChartType: (value: ChartType) => void;
  filteredClusters?: ReturnType<typeof getClustersConsumption>;
  clustersFormatted?: ReturnType<typeof getClustersConsumption>;
  isLoading: boolean;
  setFilters: React.Dispatch<React.SetStateAction<ConsumptionFiltersOptions>>;
  filters: ConsumptionFiltersOptions;
  chartType: ChartType;
  setMeasureType: React.Dispatch<React.SetStateAction<MeasureType>>;
  measureType: MeasureType;
}

function ConsumptionDetailsBar({
  filters,
  setFilters,
  setChartType,
  filteredClusters,
  clustersFormatted,
  isLoading,
  chartType,
  setMeasureType,
  measureType,
}: ConsumptionDetailsBarProps) {
  return (
    <>
      <div className="ConsumptionDetailsBar">
        <Title level={4} className="ant-typography ConsumptionDetailsBar__title truncate">
          IPU Consumption Details
        </Title>

        <div className="ConsumptionDetailsBar__actions">
          <Space>
            {/* <Select
              defaultValue={measureType}
              style={{ width: 110 }}
              onChange={(value: MeasureType) => {
                setMeasureType(value);
              }}
            >
              <Select.Option value="daily">Daily</Select.Option>
              <Select.Option value="hourly">Hourly</Select.Option>
              <Select.Option value="monthly">Monthly</Select.Option>
            </Select> */}

            {/* <Select
              defaultValue={chartType}
              onChange={(value: ChartType) => {
                setChartType(value);
              }}
            >
              <Select.Option value="line" onClick={() => setChartType('line')}>
                Aggregated
              </Select.Option>
              <Select.Option value="bar" onClick={() => setChartType('bar')}>
                Dispersed
              </Select.Option>
            </Select> */}

            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      if (filteredClusters) {
                        downloadConsumptionToCSV(filteredClusters);
                      }
                    }}
                  >
                    Download as CSV
                  </Menu.Item>
                </Menu>
              }
            >
              <MoreOutlined />
            </Dropdown>
          </Space>
        </div>
      </div>

      <ConsumptionFilter
        filters={filters}
        setFilters={setFilters}
        isLoading={isLoading}
        clustersFormatted={clustersFormatted}
      />
    </>
  );
}

export default ConsumptionDetailsBar;
