import './UnitsChart.less';

import React, { useLayoutEffect, useRef } from 'react';
import echarts from 'echarts/lib/echarts';
import useResizeObserver from 'use-resize-observer';
import { getXAxisData } from './utils';
import { getClustersConsumption } from 'utils/cluster';
import { ChartType, MeasureType } from 'components/Consumption/ConsumptionBody/ConsumptionBody';

interface UnitsChartProps {
  filteredClusters: ReturnType<typeof getClustersConsumption>;
  clusters?: any;
  chartType: ChartType;
  measureType?: MeasureType;
  height?: number;
  grid: any;
}

function UnitsChart({
  filteredClusters,
  clusters,
  chartType,
  height = 300,
  grid,
  measureType = 'daily',
}: UnitsChartProps) {
  console.log(clusters);
  let chartRef = useRef<HTMLDivElement>(null);
  let eChartInstance = useRef<echarts.ECharts | null>(null);

  useResizeObserver<HTMLDivElement>({
    ref: chartRef,
    onResize: () => {
      eChartInstance.current?.resize();
    },
  });

  useLayoutEffect(() => {
    eChartInstance.current = echarts.init(chartRef.current!);
  }, []);

  useLayoutEffect(() => {
    let xAxisData: any;
    let seriesData: any[] = [];
    filteredClusters.forEach((cluster) => {
      let days = cluster.consumption.flatMap((con) => con.days);
      xAxisData = getXAxisData(measureType, days);

      seriesData.push({
        id: cluster.id,
        name: cluster.name,
        data: xAxisData.data,
      });
    });

    eChartInstance.current!.setOption({
      animation: false,
      color: [
        '#255888',
        '#D1481A',
        '#2189A4',
        '#FFA500',
        '#4D3B70',
        '#B35681',
        '#285F6C',
        '#DF2A4B',
        '#CD7565',
        '#850004',
        '#00A6D8',
        '#62BB9A',
      ],
      tooltip: {
        formatter(params, ticket, callback) {
          let _params = params as any;
          return `
            <div classname="tooltip">
              <div><strong>Cluster</strong>: ${_params.seriesName}</div>
              <div><strong>Date</strong>: ${_params.name}</div>
              <div><strong>Total:</strong> ${_params.value} IPU</div>
              <div><strong>Incorta:</strong> ${_params.data.incorta} IPU</div>
              <div><strong>Spark:</strong> ${_params.data.spark} IPU</div>
            </div>
          `;
        },
        position: function (point: any, param, dom, rect, size: any) {
          const pos = point[0] - size.contentSize[0];

          if (pos > 0) {
            return [pos, '20%'];
          } else {
            return [point[0], '20%'];
          }
        },
      },
      grid: grid,
      dataZoom: [
        {
          handleIcon:
            'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '80%',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          },
        },
      ],
      xAxis: {
        data: xAxisData.labels,
        name: 'Date',
        ...axisStyles,
      },
      yAxis: {
        name: 'Units',
        splitLine: {
          lineStyle: {
            color: '#EEF0F2',
          },
        },
        ...axisStyles,
      },
      series: seriesData.map((series) => {
        return {
          name: series.name,
          data: series.data,
          type: chartType === 'column' ? 'bar' : 'line',
          areaStyle: {},
          emphasis: {
            focus: 'series',
          },
        };
      }),
    });
  }, [chartType, filteredClusters, grid, measureType]);

  return (
    <div
      className="UnitsChart"
      style={{
        width: '100%',
        // @ts-ignore
        '--height': `${height}px`,
      }}
    >
      <div className="UnitsChart__instance" ref={chartRef}></div>
    </div>
  );
}

const axisStyles = {
  axisLine: {
    lineStyle: {
      color: '#848F99',
    },
  },
  axisLabel: {
    color: '#848F99',
  },
  axisTick: {
    lineStyle: {
      color: '#848F99',
    },
  },
};

export default UnitsChart;
